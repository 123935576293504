<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Mis compras</span>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							  	v-model="imprimirSolicitudes"
							    :headers="headers"
							    :items="solicitudes"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							    item-key="idsolicitudes"
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip 
							      	v-if="item.estatus == 1"
							      	small
							      	color="orange"
							      	dark
							      >Incompleta</v-chip>

							      <v-chip 
							      	v-else-if="item.estatus == 0"
							      	small
							      	color="error"
							      	dark
							      >Pendiente</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="success"
							      	dark
							      >Completa</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>
						    	
							    	<v-btn 
							    		v-if="item.estatus == 0"
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>



							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idsolicitudes }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
						    :headers="headersCompra"
						    :items="editedItem.articulos"
						    class="elevation-0"
						    :search="search"
		            dense
		            :items-per-page="5"
						  >

						  	<!-- Modificar la cantidad recibida -->
						  	<template v-slot:item.cantidad_recibida="props">
					        <v-edit-dialog
					        	v-if="editedItem.estatus == 1 && props.item.estatus == 0"
					        	large
					          @save="actualizarEntrega( props.item )"
					          @cancel="cancelarEntrega()"
					          save-text="Guardar"
					          cancel-text="Cancelar"
					        >
					          {{ props.item.cantidad_recibida }}
					          <template v-slot:input>
					            <v-text-field
					              v-model="props.item.cantidad_recibida"
					              label="Cantidad recibida"
					              single-line
					              type="number"
					              :rules="[
					              	() => !!props.item.cantidad_recibida || 'Campo requerido',
					              	() => !!props.item.cantidad_recibida && props.item.cantidad_recibida <= props.item.cantidad || 'No puedes recibir más de lo solicitado',
					              ]"
					            ></v-text-field>
					          </template>

					        </v-edit-dialog>

					        <span v-else>{{ props.item.cantidad_recibida }} </span>
					      </template>

						  	<template v-slot:item.foto="{ item }">
				          <v-img 
				            :src="url + item.foto" 
				            height="60px"
				            width="60px"
				            contain 
				            v-if="item.foto"
				            class="white"
				          >
				          </v-img>
						    </template>

						    <template v-slot:item.estatus="{ item }">
						      <v-chip 
						      	v-if="item.estatus == 1"
						      	small
						      	color="green"
						      	dark
						      >Completa</v-chip>

						      <v-chip 
						      	v-else
						      	small
						      	color="error"
						      	dark
						      >Pendiente</v-chip>
						    </template>
							  
						    
						    <template v-slot:no-data>
						      <v-btn
						        color="primary"
						        @click="initialize"
						        small
						      >
						        Actualizar
						      </v-btn>
						    </template>

						  </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la solicitud?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import funcionesExcel  from '@/mixins/funcionesExcel'
  import validarErrores  from '@/mixins/validarErrores'

  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ funcionesExcel, validarErrores ],

    data: () => ({
    	url   : '' ,
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idsolicitudes:0,
        articulos:[],
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      defaultItem: {
        idsolicitudes:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      // Filtros: 
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      solicitudes: [],
      headers: [
        { text: 'ID'               , value: 'idsolicitudes'    },
        { text: 'Plantel'          , value: 'plantel'          },
        { text: 'Usuario'          , value: 'empleado'         },
        { text: 'Estatus'          , value: 'estatus'          },
        { text: 'Alta'             , value: 'fecha_creacion'   },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],


      headersCompra: [
        { text: 'id'               , value: 'iddesgloce_solicitud'    },
        { text: 'articulo'         , value: 'nomart'                  },
        { text: 'foto'             , value: 'foto'                    },
        { text: 'cantidad'         , value: 'cantidad'                },
        { text: 'Recibí'           , value: 'cantidad_recibida'       },
        { text: 'Estatus'          , value: 'estatus'                 },
      ],

      imprimirSolicitudes:[]
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Forma de pago' : 'Ver Pedido'
      },
    },

    watch: {
      // dialog (val) {
      //   val || this.close()
      // },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
    	this.url = axios.defaults.baseURL + 'imagenes-tienda/'
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.solicitudes = []
        return this.$http.get('articulos.solicitudes').then(response=>{
        	this.solicitudes = response.data.filter( el => { return el.iderp == this.getdatosUsuario.iderp })
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.solicitudes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.solicitudes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('articulos.solicitudes.eliminar/' + this.editedItem.idsolicitudes).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
      	if( this.editedItem.estatus == 1 ){
	      	// Validamos el estatus completo
	    		const existeIncompleto = this.editedItem.articulos.find( el => el.estatus == 0 )

	    		// Si si esta completo, actualizamos el estatus de la solicitud
	    		if( !existeIncompleto ){ 
	    			this.editedItem.estatus = 2 
		      	// Acutalizamos en la base de datos
		    		this.cargar = true
		        // Lo mandapos por el EP
		        this.$http.post('articulos.solicitudes.update', this.editedItem ).then(response=>{
		        	this.cargar = false
		        	this.dialog = false
			        this.$nextTick(() => {
			          this.editedItem = Object.assign({}, this.defaultItem)
			          this.editedIndex = -1
			        })
		        	this.initialize()
		        	this.validarSuccess( response.data.message )
		        }).catch( error =>{
		        	this.validarError( error.response.data.message )
		        }).finally( () => { this.cargar = false })
      		}else{
      			// Si no esta completo no se actualiza
      			this.dialog = false
		        this.$nextTick(() => {
		          this.editedItem = Object.assign({}, this.defaultItem)
		          this.editedIndex = -1
		        })
      		}
    		}else{
    			// El estatus no permite ser actualizado
	    		this.dialog = false
	        this.$nextTick(() => {
	          this.editedItem = Object.assign({}, this.defaultItem)
	          this.editedIndex = -1
	        })
    		}
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      actualizarEntrega ( value ) {
      	let { cantidad, cantidad_recibida, estatus, iddesgloce_solicitud } = value

      	if( cantidad_recibida > cantidad ){
      		for( const i in this.editedItem.articulos ){
      			if( this.editedItem.articulos[i].iddesgloce_solicitud == iddesgloce_solicitud ){
      				this.editedItem.articulos[i].cantidad_recibida = 0
      			}
      		}

      		this.validarErrorDirecto( 'La cantidad recibida no puede ser mayor a la solicitada ')
      	}else{
      		for( const i in this.editedItem.articulos ){
      			if( this.editedItem.articulos[i].iddesgloce_solicitud == iddesgloce_solicitud ){

      				// Si son las mismas cantidades, hay que decir que ya esta completa
      				if( cantidad_recibida ==  cantidad ){
      					this.editedItem.articulos[i].estatus = 1
      				}
      				// Y guardar la cantidad
      				this.editedItem.articulos[i].cantidad_recibida = cantidad_recibida

      				this.cargar = true
      				// Lo mandapos por el EP
			        this.$http.post('articulos.pedido.recibido', value ).then(response=>{
			        	this.cargar = false
			        	// this.validarSuccess( response.data.message )
			        }).catch( error =>{
			        	this.validarError( error.response.data.message )
			        }).finally( () => { this.cargar = false })
      			}
      		}
      	}
      },

      cancelarEntrega () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
      },

    },
  }
</script>


